import background from "../assets/naturopathy-background.jpg";
import icon from "../assets/naturopathy-icon.png";
import { MainPageCard } from "../components/Card";
import { Page } from "../components/Layout";

export const Naturopathy = {
  icon: { url: icon, color: "#A6CD7F" },
  title: "Naturheilkunde",
};

export const NaturopathyCard = () => (
  <MainPageCard title={Naturopathy.title} icon={Naturopathy.icon}>
    <p>
      Die Naturheilkunde kennt viele Methoden, die zur Unterstützung der Heilung
      eingesetzt werden. Dazu gehören die Pflanzenheilkunde, die
      Auriculotherapie, aus- und ableitende Verfahren wie Schröpfen,
      Baunscheidtieren, die Blutegeltherapie und das Cantharidenpflaster. Zudem
      verhelfen mir verschiedene Diagnostiken wie beispielsweise die
      Irisdiagnostik, Zungen- und Pulsdiagnostik zu einem besseren Überblick des
      individuellen Organismus.
    </p>
    <p>
      Naturheilkundliche Behandlungen werde ich nach meinem Abschluss zur
      Naturheilpraktikerin TEN auch einzeln anbieten. Bis zu meinem Abschluss im
      Jahr 2025 werde ich die Naturheilkunde vorerst als eine Erweiterung zum
      bereits bestehenden Angebot an Therapieformen nutzen.
    </p>
  </MainPageCard>
);

export default function NaturopathyPage() {
  return (
    <Page $image={background}>
      <NaturopathyCard />
    </Page>
  );
}
