import { MainPageCard } from "../components/Card";
import { Page, UnderlinedText } from "../components/Layout";
import styled from "styled-components";

const Prices = styled.div`
  margin: 5% 0;
  width: 80%;
  max-width: 400px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 10%;

  span {
    width: 60%;
    display: block;
    margin-top: 2%;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  span:nth-child(2n) {
    width: 40%;
    text-align: right;
  }

  @media (max-width: 768px) {
    span {
      margin-top: 20px;
    }
  }
`;

const Payment = styled.p`
  font-size: 1.2em;
  width: 80%;
  ${UnderlinedText}
`;

export default function PricesPage() {
  return (
    <Page>
      <MainPageCard title="Preisliste">
        <Prices>
          <span>Klassische Massage</span>
          <span>120 CHF / 60 Minuten</span>

          <span>Klassische Massage</span>
          <span>175 CHF / 90 Minuten</span>

          <span>Fussreflexzonenmassage</span>
          <span>120 CHF / 60 Minuten</span>

          <span>Dorntherapie</span>
          <span>120 CHF / 60 Minuten</span>
        </Prices>
        <Payment>Die Bezahlung erfolgt vor Ort in bar oder via TWINT.</Payment>
        <p>
          Die Kosten für die Klassische Massage werden von den Krankenkassen im
          Rahmen der Zusatzversicherung übernommen. Da jede Kasse ihre
          individuellen Regelungen hat, bitte ich Sie, sich direkt bei Ihrer
          Versicherung zu erkundigen.
        </p>
        <p>
          Absagen bis 24 Stunden vor dem Termin sind kostenfrei. Nicht
          frühzeitig abgesagte Termine werden verrechnet.
        </p>
      </MainPageCard>
    </Page>
  );
}
