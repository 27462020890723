import background from "../assets/foot-reflexology-massage-background.png";
import icon from "../assets/foot-reflexology-massage-icon.png";
import { MainPageCard } from "../components/Card";
import { Page } from "../components/Layout";

export const FootReflexology = {
  icon: { url: icon, color: "#90B0BA" },
  title: "Fussreflexzonenmassage",
};

export const FootReflexologyCard = () => (
  <MainPageCard
    title={FootReflexology.title}
    icon={FootReflexology.icon}
    bookable
  >
    <p>
      Die Theorie über die Fussreflexzonen besagt, dass sich der menschliche
      Körper in den Füssen widerspiegelt und jedes Körperteil mit einer
      bestimmte Stelle des Fusses verbunden ist. Mithilfe dieser Therapieform
      kann ein inneres Organ angeregt oder entspannt werden, welches von aussen
      nicht direkt behandelt werden kann. Zudem erhalte ich als Therapeutin
      durch das Ertasten der Füsse weitere Informationen über den individuellen
      Organismus, die Schwachstellen und die Baustellen, die der Mensch haben
      könnte. Dies hilft mir beim Versuch, den individuellen Organismus zu
      verstehen und dient der ganzheitlichen Behandlung.
      <br />
      Die Wirkungen einer Fussreflexzonenmassage sind vergleichbar mit
      denjenigen einer Klassischen Massage, jedoch organspezifischer:
    </p>
    <ul>
      <li>durchblutungsfördernd (Organspezifisch)</li>
      <li>entspannend oder anregend</li>
      <li>schmerzlindernd</li>
      <li>stärkt das Immunsystem</li>
      <li>verbessert den Schlaf</li>
    </ul>
    <p>120 CHF / 60min</p>
  </MainPageCard>
);

export default function FootReflexologyPage() {
  return (
    <Page $image={background}>
      <FootReflexologyCard />
    </Page>
  );
}
