import styled from "styled-components";

const BookAppointment = styled.iframe`
  flex: 1;
  margin-top: 55px;
  border: none;
  background-color: #cdcff3;
`;

export default function BookAppointmentPage() {
  return (
    <BookAppointment
      title="Termin Buchen"
      src="https://app.healthadvisor.ch/bookings/f62a30e87f4548ac8f8851fa6c4bd343"
    >
      Ohje, Ihr Browser unterstützt keine iframes!
    </BookAppointment>
  );
}
