import { Card, MainPageCard } from "../components/Card";
import { ColumnStyle, Page } from "../components/Layout";
import styled from "styled-components";
import { NavButton } from "../components/Button";
import me from "../assets/me.jpg";
import Icon from "../components/Icon";
import { useEffect, useState } from "react";
import { ClassicMassage, ClassicMassageCard } from "./ClassicMassage";
import { DornTherapy, DornTherapyCard } from "./DornTherapy";
import { FootReflexology, FootReflexologyCard } from "./FootReflexology";
import { Naturopathy, NaturopathyCard } from "./Naturopathy";
import { Contact, Location } from "./Contact";
import { ReactComponent as bigLogo } from "../assets/logo-big.svg";

const HomeStyledPage = styled(Page)`
  gap: 20px;
  @media (min-width: 768px) {
    gap: 50px;
  }

  & > div {
    width: 90%;
    @media (min-width: 768px) {
      width: 750px;
    }
  }
`;

const TherapyContainer = styled.div`
  ${ColumnStyle}
  gap: unset;
  height: 300px;
  justify-content: space-between;

  & > div:first-child {
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${Icon} {
      width: 75px;
      height: 75px;
    }
  }
`;

const Therapy = ({
  icon,
  title,
  description,
  to,
}: {
  icon: { url: string; color: string };
  title: string;
  description: string;
  to: string;
}) => (
  <TherapyContainer>
    <div>
      <Icon $icon={icon.url} $color={icon.color} />
      <h2>{title}</h2>
    </div>
    <p>{description}</p>
    <NavButton to={to} viewTransition>
      Mehr dazu
    </NavButton>
  </TherapyContainer>
);

const SplitContent = styled.div<{ $border?: boolean }>`
  display: flex;
  width: 100%;
  & > * {
    padding: 10% 5%;
    width: 50%;
    ${ColumnStyle}
  }
  ${({ $border }) =>
    $border &&
    `& > *:last-child {
      border-left: 1px solid #ddd;
      @media (max-width: 768px) {
        border-left: none;
        border-top: 1px solid #ddd;
      }
    }`}

  @media (max-width: 768px) {
    flex-direction: column;
    & > * {
      width: unset;
      padding: 20px;
    }
  }
`;

const TherapyContent = styled(SplitContent)`
  margin: 20px 0;
  & > div {
    padding: 0;
    gap: 50px;
  }
  & > div:first-child > ${TherapyContainer} {
    padding-right: 50px;
  }
  & > div:last-child > ${TherapyContainer} {
    padding-left: 50px;
  }
`;

const AboutMeImage = styled.img`
  height: 100%;
  width: 50%;
  object-fit: cover;
  align-self: start;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Logo = styled(bigLogo)`
  height: 150px;
  fill: #9c77ff;
`;

const Intro = styled.div`
  ${ColumnStyle}
  color: #9c77ff;

  p,
  span {
    font-weight: bold;
  }

  span {
    font-size: 1.2em;
  }
`;

export default function HomePage() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <HomeStyledPage>
      {isMobile ? (
        <>
          <ClassicMassageCard />
          <DornTherapyCard />
          <FootReflexologyCard />
          <NaturopathyCard />
        </>
      ) : (
        <>
          <Intro>
            <span>Willkommen in der</span>
            <Logo />
            <p>für manuelle Therapie und Naturheilkunde</p>
          </Intro>
          <MainPageCard title="Therapieangebote" bookable>
            <p>
              Jede Therapie hat einen eigenen Schwerpunkt. Gerne dürfen Sie mich
              kontaktieren, um die für Sie passende Therapieform zu finden.
            </p>
            <TherapyContent $border>
              <div>
                <Therapy
                  title={ClassicMassage.title}
                  icon={ClassicMassage.icon}
                  description="Zur Linderung von körperlichen Beschwerden und zur Entspannung von Körper und Geist. Die klassische Massage ist ein gutes Mittel, um die körperliche und geistige Balance des Menschen wiederherzustellen."
                  to="/angebote/klassische-massage"
                />
                <Therapy
                  title={DornTherapy.title}
                  icon={DornTherapy.icon}
                  description="Durch die Richtmehthode nach Dorn wird sichergestellt, dass das Becken, die Wirbel und die Gelenke korrekt ausgerichtet sind, sodass der Körper wieder unbelasteter funktionieren kann. Ist diese Grundlage gesetzt, kann sich die muskuläre Balance wieder einpendeln."
                  to="/angebote/dorntherapie"
                />
              </div>
              <div>
                <Therapy
                  title={FootReflexology.title}
                  icon={FootReflexology.icon}
                  description="Der ganze Körper ist über Reflexzonen am Fuss abgebildet. Über die Organzonen am Fuss können die jeweiligen Organe angeregt oder beruhigt werden."
                  to="/angebote/fussreflexzonenmassage"
                />
                <Therapy
                  title={Naturopathy.title}
                  icon={Naturopathy.icon}
                  description="Die Naturheilkunde hat viele Methoden, die zur Unterstützung der Heilung eingesetzt werden. Da ich noch in der Ausbildung zur Natuheilpraktikerin TEN bin, zähle ich fortlaufende weitere Themengebiete zu meinem Wissensschatz. "
                  to="/angebote/naturheilkunde"
                />
              </div>
            </TherapyContent>
          </MainPageCard>
        </>
      )}

      <Card>
        <SplitContent>
          <AboutMeImage src={me} alt="Portrait Anna Müller" />
          <div>
            <h1>Über mich</h1>
            <p>
              Zur Zeit bin ich in der Ausbildung zur Naturheilpraktikerin. Seit
              August 2021 bis im Frühjahr 2023 erlernte ich die
              Fussreflexzonenmassage sowie auch die Dorntherapie. Seit Ende 2022
              bin ich diplomierte Berufsmasseurin in Klassischer Massage.
            </p>
            <NavButton to="/ueber-mich" viewTransition>
              Mehr zu mir
            </NavButton>
          </div>
        </SplitContent>
      </Card>

      <Card>
        <SplitContent $border>
          <div>
            <h1>{Location.title}</h1>
            {Location.content()}
          </div>
          <div>
            <h1>{Contact.title}</h1>
            {Contact.content()}
            <NavButton to="/termin-buchen" viewTransition>
              Termin buchen
            </NavButton>
          </div>
        </SplitContent>
      </Card>
    </HomeStyledPage>
  );
}
