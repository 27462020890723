import { MainPageCard } from "../components/Card";
import { Page } from "../components/Layout";
import styled from "styled-components";
import StyledLink from "../components/Link";

const Header = styled.h2`
  font-weight: bold;
`;

const LegalItem = styled.div`
  display: block;
  width: 100%;
  text-align: center;

  > p {
    margin: 0;
  }
`;

export default function LegalNoticePage() {
  return (
    <Page>
      <MainPageCard title="Impressum">
        <LegalItem>
          <Header>Website Betreiberin:</Header>
          <p>Anna Müller</p>
        </LegalItem>

        <LegalItem>
          <Header>Adresse:</Header>
          <p>Praxis Lavandula</p>
          <p>Kramgasse 33</p>
          <p>3011 Bern</p>
        </LegalItem>

        <LegalItem>
          <Header>Kontakt:</Header>
          <p>
            Telefon:{" "}
            <StyledLink href="tel:0795411733">079 541 17 33</StyledLink>
          </p>
          <p>
            Email:{" "}
            <StyledLink href="mailto:info@praxis-lavandula.ch">
              info@praxis-lavandula.ch
            </StyledLink>
          </p>
        </LegalItem>

        <LegalItem>
          <Header>Handelsregisternummer:</Header>
          <p>CHE-184.206.571</p>
        </LegalItem>

        <LegalItem>
          <Header>Fotos:</Header>
          <p>Luis Gautschi</p>
        </LegalItem>

        <LegalItem>
          <Header>Logo Design:</Header>
          <p>Lea Knutti</p>
          <StyledLink target="_blank" href="https://www.leaknutti.ch/">
            www.leaknutti.ch
          </StyledLink>
        </LegalItem>

        <LegalItem>
          <Header>Website Developer:</Header>
          <p>Ben Wolf</p>
        </LegalItem>
      </MainPageCard>
    </Page>
  );
}
