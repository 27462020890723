import background from "../assets/classic-massage-background.png";
import icon from "../assets/classic-massage-icon.png";
import { MainPageCard } from "../components/Card";
import { Page } from "../components/Layout";

export const ClassicMassage = {
  icon: { url: icon, color: "#E89980" },
  title: "Klassische Massage",
};

export const ClassicMassageCard = () => (
  <MainPageCard
    title={ClassicMassage.title}
    icon={ClassicMassage.icon}
    bookable
  >
    <p>
      Die Klassische Massage ist weit mehr als nur eine Wohlfühlmassage. Sie ist
      vielmehr eine Therapieform, welche auf Massagetechniken basiert. Das Ziel
      der klassischen Massage ist es, körperliche Beschwerden zu lindern und die
      Seele zu entspannen.
      <br />
      Fühlen Sie sich unwohl und angespannt, ist die klassische Massage ein
      gutes Mittel, um die körperliche sowie geistige Balance
      wiederherzustellen. Folgende Wirkungen hat die Klassische Massage auf den
      Menschen:
    </p>
    <ul>
      <li>durchblutungsfördernd</li>
      <li>lymphflussanregend</li>
      <li>entspannend</li>
      <li>schmerzlindernd</li>
      <li>stärkt das Immunsystem</li>
      <li>verbessert den Schlaf</li>
    </ul>
    <p>
      120 CHF / 60min.
      <br />
      175 CHF / 90min.
    </p>
  </MainPageCard>
);

export default function ClassicMassagePage() {
  return (
    <Page $image={background}>
      <ClassicMassageCard />
    </Page>
  );
}
