import styled from "styled-components";

export default styled.div<{ $icon: string; $color?: string }>`
  background-color: ${({ $color }) => $color};
  border-radius: 50%;
  width: 20%;
  max-width: 100px;
  position: relative;
  &::after {
    top: 0;
    content: "";
    position: absolute;
    display: block;
    width: 80%;
    height: 80%;
    margin: 10%;
    background-image: url("${({ $icon }) => $icon}");
    background-size: contain;
    background-position: center;
  }
  &::before {
    display: block;
    content: "";
    padding-top: 100%;
  }
`;
