import React, { ReactNode } from "react";
import styled from "styled-components";
import { NavButton } from "./Button";
import Icon from "./Icon";
import { ColumnStyle, UnderlinedText } from "./Layout";

export const Card = styled.div`
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  box-sizing: border-box;
`;

const Container = styled(Card)`
  ${ColumnStyle}
  padding: 20px;
  width: 90%;
  @media (min-width: 768px) {
    width: 50vw;
    max-width: 1000px;
    padding: 20px 50px;
  }
`;

const TitleContainer = styled.div`
  ${UnderlinedText}
  text-align: center;
`;

export const MainPageCard: React.FC<{
  title: string;
  subtitle?: string;
  icon?: { url: string; color: string };
  bookable?: boolean;
  children: ReactNode;
}> = ({ title, subtitle, icon, children, bookable }) => {
  return (
    <Container>
      <TitleContainer>
        <h1>{title}</h1>
        {subtitle && <p>{subtitle}</p>}
      </TitleContainer>
      {icon && <Icon $icon={icon.url} $color={icon.color} />}
      {children}
      {bookable && (
        <NavButton to="/termin-buchen" viewTransition>
          Termin buchen
        </NavButton>
      )}
    </Container>
  );
};
