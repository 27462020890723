import { MainPageCard } from "../components/Card";
import background from "../assets/contact-background.png";
import Icon from "../components/Icon";
import { Page } from "../components/Layout";
import phone from "../assets/phone.png";
import email from "../assets/email.png";
import styled from "styled-components";
import Link from "../components/Link";

const Address = styled.iframe`
  background-color: #cdcff3;
  border: none;
  width: 100%;
  min-height: 250px;
`;

const ContactContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 20px;
  padding: 20px 50px 20px 0;
  width: 230px;
  justify-content: space-between;
  text-align: center;

  @media (max-width: 768px) {
    padding: 20px 0;
    text-align: left;
  }

  & > * {
    width: 175px;
  }

  ${Icon} {
    width: 35px;
  }
`;

const OpeningHours = styled.div`
  margin-bottom: 20px;
`;

export const Location = {
  title: "Praxis",
  content: () => (
    <>
      <p>
        Meine Praxis befindet sich in der Berner Altstadt. Der Eingang befindet
        sich rechts vom Schweizer Chocolatier Casa Nobile.
        <br />
        Im Gebäude ist ein Lift vorhanden.
      </p>
      <p>
        Adresse:
        <br />
        Kramgasse 33
        <br />
        3011 Bern
      </p>
      <Address
        title="Praxis Lavandula Addresse"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10894.829521456797!2d7.450580000000001!3d46.94779!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478e3953b1807761%3A0x5545c67ea2745500!2sPraxis%20Lavandula!5e0!3m2!1sen!2sch!4v1714832171744!5m2!1sen!2sch"
        loading="lazy"
      />
    </>
  ),
};

export const Contact = {
  title: "Kontakt",
  content: () => (
    <>
      <ContactContainer>
        <Icon $icon={phone} />
        <span>
          <Link href="tel:0795411733">079 541 17 33</Link>
        </span>

        <Icon $icon={email} />
        <span>
          <Link href="mailto:info@praxis-lavandula.ch">
            info@praxis-lavandula.ch
          </Link>
        </span>
      </ContactContainer>
      <OpeningHours>
        <p>Öffnungszeiten:</p>
        <p>
          Dienstag 8:30-11:30
          <br />
          Mittwoch 8:30-18:00
          <br />
          Donnerstag 8:30-13:00
          <br />
          Freitag 14:00-18:00
        </p>
        <p>oder nach Vereinbarung</p>
      </OpeningHours>
    </>
  ),
};

export default function ContactPage() {
  return (
    <Page $image={background}>
      <MainPageCard title={Location.title}>{Location.content()}</MainPageCard>
      <MainPageCard title={Contact.title} bookable>
        {Contact.content()}
      </MainPageCard>
    </Page>
  );
}
