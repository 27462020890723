import { MainPageCard } from "../components/Card";
import { Page } from "../components/Layout";
import me from "../assets/me.jpg";
import signature from "../assets/signature.png";
import styled from "styled-components";

const Signature = styled.div`
  margin-top: 25px;
  text-align: center;

  img {
    display: block;
    margin-bottom: 10px;
    width: 100%;
    max-width: 250px;
  }
`;

const MeImage = styled.img`
  width: 100%;
  margin: 25px 0 50px 0;
`;

const Career = styled.div`
  ul {
    list-style: none;
    padding: 0;
  }
  margin-bottom: 50px;
`;

export default function AboutMePage() {
  return (
    <Page>
      <MainPageCard title="Über mich">
        <p>
          Seit ich denken kann, macht mir der soziale Austausch in jeglicher
          Form Freude und bereichert mein Leben. Nach der Matura absolvierte ich
          ein Praktikum in der Alterspflege, welches mir zeigte, wie wertvoll
          die Gesundheit ist. Ohne Druck oder Erwartungen startete ich einen
          Massagekurs und folgte somit meiner Intuition. Sofort fühlte ich mich
          im richtigen Arbeitsfeld angekommen und so kam der Stein ins Rollen.
          Kurz darauf startete ich die breite Ausbildung zur
          Naturheilpraktikerin und erhielt damit einhergehend ein
          ganzheitlicheres Verständnis des Menschen. Mittlerweile bin ich in der
          Hälfte meiner Ausbildung angelangt und immer noch begeistert und
          fasziniert. Die Ausbildung zur diplomierten Berufsmasseurin in
          Klassischer Massage habe ich bereits Ende 2022 abgeschlossen. Weitere
          manuelle Therapien, welche ich im Rahmen meiner Ausbildung erlernte,
          sind Fussreflexzonenmassage, Dorntherapie und andere
          naturheilkundliche Methoden wie beispielsweise die Phytotherapie.
        </p>
        <p>
          Mein erlerntes Wissen und meine Intuition verbinde ich, um ein
          individuelles Therapiekonzept zusammenzustellen. Mein Ziel ist es,
          durch Reize die Selbstregulation und die damit einhergehende
          Selbstheilung anzuregen.
          <br />
          Es würde mich sehr freuen Sie als Menschen behandeln zu dürfen.
        </p>
        <Signature>
          <img src={signature} alt="Unterschrift Anna Müller" />
          <span>Anna Müller</span>
        </Signature>
        <MeImage src={me} alt="Portrait Anna Müller" />
        <Career>
          <p>Mein Werdegang: </p>
          <ul>
            <li>2021 - heute in Ausbildung zur Naturheilpraktikerin TEN</li>
            <li>2020 - heute Büroangestellte</li>
          </ul>
          <ul>
            <li>2024 Weiterbildung Triggerpunkttherapie</li>
            <li>2021-2023 Weiterbildung Fussreflexzonenmassage </li>
            <li>2022 Weiterbildung Dorntherapie</li>
            <li>2021-2022 Ausbildung zur klassischen Masseurin</li>
            <li>2020 Praktikum in Alterspflege</li>
            <li>2015-2019 Matura Gymnasium Kirchenfeld</li>
          </ul>
        </Career>
      </MainPageCard>
    </Page>
  );
}
