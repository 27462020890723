import styled from "styled-components";
import { NavLinkStyled } from "./Nav";

const StyledFooter = styled.footer`
  display: flex;
  background-color: white;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  z-index: 0;

  @media (max-width: 768px) {
    padding: 0 0.5rem;
  }

  @media (max-width: 380px) {
    padding: 0.5rem;
  }
`;

const LinksContainer = styled.div`
  flex: 0;
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    gap: 10px;
  }

  @media (max-width: 380px) {
    flex-wrap: wrap;
  }
`;

const Footer = () => (
  <StyledFooter>
    <LinksContainer>
      <NavLinkStyled to="/impressum">Impressum</NavLinkStyled>
      <NavLinkStyled to="/datenschutzerklaerung">
        Datenschutzerklärung
      </NavLinkStyled>
    </LinksContainer>
    <NavLinkStyled to="/">
      <p>© {new Date().getFullYear()} Anna Müller</p>
    </NavLinkStyled>
  </StyledFooter>
);

export default Footer;
