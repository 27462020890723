import background from "../assets/dorn-therapy-background.png";
import icon from "../assets/dorn-therapy-icon.png";
import { MainPageCard } from "../components/Card";
import { Page } from "../components/Layout";

export const DornTherapy = {
  icon: { url: icon, color: "#E5CB95" },
  title: "Dorntherapie",
};

export const DornTherapyCard = () => (
  <MainPageCard title={DornTherapy.title} icon={DornTherapy.icon} bookable>
    <p>
      Durch die Richtmethode nach Dorn wird sichergestellt, dass das Becken, die
      Wirbel und die Gelenke korrekt ausgerichtet sind, sodass der Körper wieder
      unbelasteter funktionieren kann. Ist diese Grundlage gesetzt, kann sich
      die muskuläre Balance wieder einpendeln.
      <br />
      Meine Dorntherapie besteht aus zwei Komponenten. Einerseits werden die
      knöchernen Strukturen gerichtet und andererseits wird durch die klassische
      Massage die umliegende Muskulatur gelockert. Bei dieser Therapieform
      helfen regelmässige Folgebehandlungen für eine nachhaltige Wirkung.
      <br />
      Schon nach der ersten Behandlung ist es möglich, dass Sie folgende
      Verbesserungen wahrnehmen:
    </p>
    <ul>
      <li>Verbesserung der Durchblutung</li>
      <li>Ein Gefühl von Leichtigkeit</li>
      <li>Linderung von Gelenkschmerzen</li>
      <li>Stärkung des Immunsystems</li>
      <li>Verbesserung des Schlafs</li>
    </ul>
    <p>120 CHF / 60min</p>
  </MainPageCard>
);

export default function DornTherapyPage() {
  return (
    <Page $image={background}>
      <DornTherapyCard />
    </Page>
  );
}
