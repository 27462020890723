import { MainPageCard } from "../components/Card";
import { Page } from "../components/Layout";
import styled from "styled-components";
import StyledLink from "../components/Link";

const List = styled.ol`
  counter-reset: item;
  width: 100%;
  padding: 0;
  hyphens: auto;

  ol,
  ul {
    counter-reset: item;
    margin-top: 10px;
    padding-left: 20px;
  }
  ul {
    list-style-type: circle;
    margin-bottom: 16px;
  }
  > li,
  ol > li {
    display: block;
  }
  > li:before {
    font-size: 1.2em;
    font-weight: bold;
  }
  > li:before,
  ol > li:before {
    content: counters(item, ".") " ";
    counter-increment: item;
  }

  h2,
  h3 {
    padding-left: 20px;
    display: inline;
    font-weight: bold;
  }
`;

export default function PrivacyPolicyPage() {
  return (
    <Page>
      <MainPageCard
        title="Datenschutzerklärung"
        subtitle="nach den Vorgaben des Schweizer Bundesgesetzes über den Datenschutz (Datenschutzgesetz – DSG) "
      >
        <List>
          <li>
            <h2>Identität und die Kontaktdaten der Verantwortlichen</h2>
            <p>
              Der Verantwortliche im Sinne des DSG ist die:
              <br />
              Praxis Lavandula
              <br />
              Kramgasse 33
              <br />
              3011 Bern
              <br />
              Schweiz
              <br />
              Tel.: <StyledLink href="tel:0795411733">079 541 17 33</StyledLink>
              <br />
              E-Mail:{" "}
              <StyledLink href="mailto:info@praxis-lavandula.ch">
                info@praxis-lavandula.ch
              </StyledLink>
              <br />
              Website:{" "}
              <StyledLink href="https://praxis-lavandula.ch/">
                www.praxis-lavandula.ch
              </StyledLink>
            </p>
          </li>

          <li>
            <h2>Bereitstellung der Website (Logfiles)</h2>
            <ol>
              <li>
                <h3>Beschreibung und Umfang der Datenbearbeitung</h3>
                <p>
                  Wenn Sie auf unsere Website zugreifen, d.h., selbst wenn Sie
                  sich nicht registrieren oder anderweitig Informationen
                  übermitteln, werden automatisch Informationen allgemeiner
                  Natur erfasst. Diese Informationen (Server-Logfiles)
                  beinhalten etwa die Art des Webbrowsers, das verwendete
                  Betriebssystem, den Domainnamen Ihres
                  Internet-Service-Providers, Ihre IP-Adresse, Referrer-URL,
                  Datum und Uhrzeit des Zugriffs und ähnliches.
                  <br />
                  Sie werden insbesondere zu folgenden Zwecken bearbeitet:
                </p>
                <p>
                  (1)   Sicherstellung eines problemlosen Verbindungsaufbaus der
                  Website,
                  <br />
                  (2)   Sicherstellung einer reibungslosen Nutzung unserer
                  Website, sowie
                  <br />
                  (3)   Sicherstellung und Auswertung der Systemsicherheit und
                  -stabilität, insbesondere zur Missbrauchserkennung sowie
                  <br />
                  (4)   zur technisch fehlerfreien Darstellung und Optimierung
                  unserer Website.
                </p>
                <p>
                  Die Daten werden ebenfalls in den Logfiles unseres Systems
                  gespeichert. Eine Speicherung dieser Daten zusammen mit
                  anderen Personendaten des Nutzers findet nicht statt. Wir
                  verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu
                  ziehen. Allerdings behalten wir uns vor, die Server-Logfiles
                  nachträglich zu überprüfen, sollten konkrete Anhaltspunkte auf
                  eine rechtswidrige Nutzung hinweisen.
                </p>
              </li>

              <li>
                <h3>Empfänger der Daten</h3>
                <p>
                  Wir setzen für Betrieb und Wartung unserer Webseite
                  Dienstleister ein, die als unsere Auftragsbearbeiter tätig
                  werden. Sämtliche Dienstleister sind vertraglich dazu
                  verpflichtet, Ihre Daten vertraulich zu behandeln.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <h2>Onlinebuchungstool</h2>
            <ol>
              <li>
                <h3>Beschreibung und Umfang der Datenbearbeitung</h3>
                <p>
                  Zum Zeitpunkt der Absendung der Nachricht werden zudem
                  folgende Daten übermittelt und gespeichert:
                  <br />
                  (1)   Datum und Uhrzeit der Anfrage
                  <br />
                  (2)   URL, von der die Anfrage erfolgte
                  <br />
                  (3)   IP-Adresse
                </p>
                <p>
                  Alternativ ist eine Kontaktaufnahme über die bereitgestellten
                  E-Mail-Adressen möglich. In diesem Fall werden die mit der
                  E-Mail übermittelten Personendaten des Nutzers gespeichert.
                  Hierzu zählen:
                  <br />
                  (4)   Datum und Uhrzeit der Anfrage
                  <br />
                  (5)   URL, von der die Anfrage erfolgte sowie Informationen zu
                  den an der E-Mail-Kommunikation beteiligten Servern. Zudem
                  können Sie über die bereitgestellte Telefonnummer Kontakt zu
                  uns aufnehmen. Hierbei erheben wir Protokolldaten, die Ihre
                  Telefonnummer und die Dauer des Gesprächs beinhalten. Wir
                  zeichnen Gespräche grundsätzlich nicht auf.
                </p>
              </li>

              <li>
                <h3>Empfänger der Daten</h3>
                <p>
                  Unsere Website wird von Dienstleistern gewartet, die als
                  unsere Auftragsbearbeiter tätig werden. Sofern Sie uns eine
                  Anfrage bzgl. eines Angebots schicken, können von uns
                  eingesetzte Dienstleister zu diesen Zwecken Daten erhalten,
                  sofern diese die Daten zur Erfüllung ihrer jeweiligen Leistung
                  benötigen (z.B. IT-Dienstleistungen, Call-Center). Sämtliche
                  Dienstleister sind vertraglich dazu verpflichtet, Ihre Daten
                  vertraulich zu behandeln.
                </p>
              </li>

              <li>
                <h3>Verwendung technisch nicht notwendiger Cookies</h3>
                <p>
                  Wir verwenden auf der Website solche Cookies, die eine Analyse
                  des Surfverhaltens der Nutzer ermöglichen. Diese Cookies
                  kommen zum Einsatz, um die Nutzung der Website effizienter und
                  attraktiver zu gestalten. Wir nutzen Webanalyse Technologien
                  folgender Anbieter:
                  <br />
                  (1)   Google Analytics
                </p>
              </li>

              <li>
                <h3>Empfänger der Daten</h3>
                <p>
                  Wir setzen für Betrieb und Wartung unserer Webseite technische
                  Dienstleister ein, die als unsere Auftragsbearbeiter tätig
                  werden.
                  <br /> Weitere Empfänger und Einzelheiten über die technische
                  Funktionsweise der eingesetzten Tools und Informationen, wie
                  Sie die Datenübermittlung (Tracking) verhindern können, finden
                  Sie in der unten aufgeführten Tabelle.
                  <br />
                  Sämtliche Dienstleister sind vertraglich dazu verpflichtet,
                  Ihre Daten vertraulich zu behandeln.
                </p>
              </li>

              <li>
                <h3>Eingesetzte Cookies</h3>
                <ul>
                  <li>
                    Health Advisor gehostet von Flow Swiss AG für das
                    Buchungstool.
                  </li>
                  <li>Hosttech gehostet von Hosttech GmbH für die Website.</li>
                </ul>
              </li>
            </ol>
          </li>

          <li>
            <h2>Reichweitenmessung</h2>
            <ol>
              <li>
                <h3>Beschreibung und Umfang der Datenbearbeitung</h3>
                <p>
                  Mithilfe von Analysewerkzeugen werten wir das Nutzerverhalten
                  aus. Eine genauere Beschreibung zu den Tools finden Sie im
                  Kapitel „Analysewerkzeuge“ dieser Datenschutzerklärung. Die
                  Verarbeitung erfolgt auf Basis unseres berechtigten
                  Interesses. Die Messung der Reichweite und die sich daraus
                  ergebenden Informationen sind geeignet, um das Webangebot
                  anzupassen.
                </p>
              </li>

              <li>
                <h3>Empfänger der Daten</h3>
                <p>
                  Wir setzen für Betrieb und Wartung unserer Webseite technische
                  Dienstleister ein, die als unsere Auftragsbearbeiter tätig
                  werden. Sämtliche Dienstleister sind vertraglich dazu
                  verpflichtet, Ihre Daten vertraulich zu behandeln.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <h2>Rechte der betroffenen Person</h2>
            <p>
              Sie haben die folgenden Rechte in Bezug auf Personendaten, die Sie
              betreffen:
            </p>
            <ul>
              <li>
                das Recht, Auskunft darüber zu erhalten, welche Personendaten
                wir von Ihnen speichern und wie wir diese bearbeiten;
              </li>
              <li>
                das Recht auf Herausgabe einer Kopie Ihrer Personendaten in
                einem gängigen Format;
              </li>
              <li>das Recht auf Berichtigung Ihrer Personendaten;</li>
              <li>das Recht auf Löschung Ihrer Personendaten;</li>
              <li>
                das Recht, Bearbeitungen Ihrer Personendaten zu widersprechen.
              </li>
            </ul>
            <p>
              Beachten Sie bitte, dass für diese Rechte Ausnahmen gelten. Soweit
              rechtlich zulässig, können wir Ihre Anfrage zur Ausübung dieser
              Rechte ablehnen. Eine erteilte Einwilligung in die Verarbeitung
              personenbezogener Daten können Sie jederzeit uns gegenüber
              widerrufen. Bitte beachten Sie, dass der Widerruf erst für die
              Zukunft wirkt. Bearbeitungen, die vor dem Widerruf erfolgt sind,
              sind davon nicht betroffen. Für eine Auskunft über die von uns
              bearbeiteten Personendaten, senden Sie Ihr Auskunftsbegehren bitte
              an info@praxis-lavandula.ch. Sie haben ausserdem das Recht, beim
              Eidgenössischen Datenschutz- und Öffentlichkeitsbeauftragten
              (EDÖB) eine Beschwerde einzureichen.
            </p>
          </li>
        </List>

        <span>
          Diese Datenschutzerklärung wurde mit Hilfe von{" "}
          <StyledLink href="https://www.activemind.ch/" target="_blank">
            www.activemind.ch
          </StyledLink>{" "}
          erstellt - den Experten für Datenschutz und Informationssicherheit.
        </span>
      </MainPageCard>
    </Page>
  );
}
